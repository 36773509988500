@import "../../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  height: 212px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(225, 225, 225, 0.37);
}

.inner {
  width: 100%;
  padding: 56px calc(calc(100% - var(--grid-100)) / 2) 44px;
  z-index: 1;
  min-height: 100%;
}

.title {
  padding-bottom: 28px;
}

.textInfo {
  font-size: 34px !important;
  font-family: var(--font-header) !important;
  color: var(--color-info) !important;
}

.options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select {
  margin-right: 40px;
}

@media (max-width: 1280px) {
  .root {
    height: auto;
  }

  .inner {
    width: 100%;
    padding: 56px 15% 44px;
  }

  .options {
    flex-direction: column;
    padding-bottom: 25px;
  }

  .select {
    margin-right: 0;
    width: 100% !important;
    margin-bottom: 18px;
  }

  .secondSelect {
    width: 100% !important;
  }

  .title {
    word-wrap: break-word;
  }
}