@font-face {
  font-family: 'YesevaOne';
  src: url('../fonts/YesevaOne.woff2') format('woff2'),
  url('../fonts/YesevaOne.woff') format('woff'),
  url('../fonts/YesevaOne.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muller';
  src: url('../fonts/MullerMedium.woff2') format('woff2'),
  url('../fonts/MullerMedium.woff') format('woff'),
  url('../fonts/MullerMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Muller';
  src: url('../fonts/MullerRegular.woff2') format('woff2'),
  url('../fonts/MullerRegular.woff') format('woff'),
  url('../fonts/MullerRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

