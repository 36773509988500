@import "../../styles/core/variables.css";

.wrapper {
  width: 25px;
  height: 25px;
  background-position: center;
  background-repeat: no-repeat;
}

.admin {
  background-image: url("./assets/admin.svg");
}
.personal {
  background-image: url("./assets/personal.svg");
}
.sharer {
  background-image: url("./assets/sharer.svg");
}
.register {
  background-image: url("./assets/registred.svg");
}
.verified {
  background-image: url("./assets/verified.svg");
}
.awaiting {
  background-image: url("./assets/wait.svg");
}
.rejected {
  background-image: url("./assets/reject.svg");
}








