@import "../../../styles/core/variables.css";

.icon {
  position: relative;
  z-index: 2;
}

.icon {
  vertical-align: top;
}

.before {
  margin-right: var(--indent-xs);
}

.after {
  margin-left: var(--indent-xs);
}
