@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: 96px;
  height: 103px;
  position: relative;
  margin-top: -15px;
  margin-right: 5px;
  margin-left: 5px;
}

.wrapper:after {
  content: '';
  width: 96px;
  height: 103px;
  top: 0;
  left: 0;
  background-image: url("./chair.svg");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 20;
}

.personage {
  width: 156px;
  height: 203px;
  position: absolute;
  bottom: 70px;
  left: 10px;
}

.personage:after {
  content: '';
  width: 156px;
  height: 203px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-position: 0 100%;
  background-repeat: no-repeat;
}

.personage.up {
}

.up {
  left: 2px;
}

.inverse {
  transform: scaleX(-1);
}

.badger {
  height: 31px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 22;
  font-family: var(--font-main);
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}


.you {
  width: 69px;
  height: 31px;
  top: 20px;
  position: relative;
  z-index: 25;
}

.you:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 69px;
  height: 31px;
  background-image: linear-gradient(to bottom, #e7cfb5, #c39b70);
  z-index: -1;
}
.you:after {
  position: absolute;
  top: -5px;
  right: 50%;
  width: 10px;
  height: 10px;
  border-left: solid 1px #ccc;
  border-top: solid 1px #ccc;
  transform: translateX(50%) rotate(134deg) skewX(-10deg) skewY(-10deg);
  background: #e7cfb5;
  content: '';
  z-index: -2;
}

.leader {
  width: 93px;
  height: 36px;
  top: 20px;
  position: relative;
  z-index: 25;
}

.leader:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 93px;
  height: 36px;
  background-image: linear-gradient(to bottom, #51b787, #209464);
  z-index: -1;
}
.leader:after {
  position: absolute;
  top: -5px;
  right: 50%;
  width: 10px;
  height: 10px;
  border-left: solid 1px #ccc;
  border-top: solid 1px #ccc;
  transform: translateX(50%) rotate(134deg) skewX(-10deg) skewY(-10deg);
  background: #51b787;
  content: '';
  z-index: -2;
}


.skinDefault:after {
  background-image: url("./skins/skin1.svg");
}
.skinDefault.up:after {
  background-image: url("./skins/skin1_up.svg");
}

.skin1:after {
  background-image: url("./skins/skin1.svg");
}
.skin1.up:after {
  background-image: url("./skins/skin1_up.svg");
}

.skin2:after {
  background-image: url("./skins/skin2.svg");
}
.skin2.up:after {
  background-image: url("./skins/skin2_up.svg");
}

.skin3:after {
  background-image: url("./skins/skin3.svg");
}
.skin3.up:after {
  background-image: url("./skins/skin3_up.svg");
}

.skin4:after {
  background-image: url("./skins/skin4.svg");
}
.skin4.up:after {
  background-image: url("./skins/skin4_up.svg");
}

.skin5:after {
  background-image: url("./skins/skin5.svg");
}
.skin5.up:after {
  background-image: url("./skins/skin5_up.svg");
}

.skin6:after {
  background-image: url("./skins/skin6.svg");
}
.skin6.up:after {
  background-image: url("./skins/skin6_up.svg");
}

.skin7:after {
  background-image: url("./skins/skin7.svg");
}
.skin7.up:after {
  background-image: url("./skins/skin7_up.svg");
}

.skin8:after {
  background-image: url("./skins/skin8.svg");
}
.skin8.up:after {
  background-image: url("./skins/skin8_up.svg");
}

.skin9:after {
  background-image: url("./skins/skin9.svg");
}
.skin9.up:after {
  background-image: url("./skins/skin9_up.svg");
}

.skin10:after {
  background-image: url("./skins/skin10.svg");
}
.skin10.up:after {
  background-image: url("./skins/skin10_up.svg");
}

.skin11:after {
  background-image: url("./skins/skin11.svg");
}
.skin11.up:after {
  background-image: url("./skins/skin11_up.svg");
}

.skin12:after {
  background-image: url("./skins/skin12.svg");
}
.skin12.up:after {
  background-image: url("./skins/skin12_up.svg");
}

.skin13:after {
  background-image: url("./skins/skin13.svg");
}
.skin13.up:after {
  background-image: url("./skins/skin13_up.svg");
}

.skin14:after {
  background-image: url("./skins/skin14.svg");
}
.skin14.up:after {
  background-image: url("./skins/skin14_up.svg");
}

.bet {
  height: 33px;
  width: 87px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 23;
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: bold;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bet.inverse {
  transform: scaleX(-1);
  left: 0;
}