@import "../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 62px;
}

.title {
  color: var(--color-white);
  margin-bottom: 55px;
  text-align: center;
}

.textInfo {
  color: var(--color-info);
}

.wrapper {
  width: 100%;
  padding: 0 calc(calc(100% - var(--grid-100)) / 2);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2vw;
}

.big {
  grid-template-columns: 1fr;
}

.line {
  grid-template-columns: 1fr;
}

.archive {
  grid-template-columns: 1fr 1fr 1fr;
}

.wrapper > * {

}

@media (max-width: 1280px) {
  .root {
    width: 100%;
    padding-top: 28px;
    padding-bottom: 50px;
  }
  .title > * {
    font-size: 28px;
  }
  .wrapper {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2vw;
  }
}

@media (max-width: 780px) {
  .root {
    width: 100%;
    padding-top: 28px;
    padding-bottom: 50px;
  }
  .title {
    margin-bottom: 28px;
  }
  .title > * {
    font-size: 28px;
  }
  .wrapper {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vw;
    padding: 0 5px;
  }
}