@import "../../../../assets/styles/core/variables.css";

.input {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.input label {
  padding-bottom: 10px;
  display: block;
}

.wrapper {
  position: relative;
  width: fit-content;
}

.wrapper.dark {
}

.wrapper.light {
  border: 1px solid var(--color-white);
  width: var(--input-width);
  height: var(--input-height);
  color: var(--color-default);
  line-height: 100%;
}


.wrapper.outline {
}

.wrapper input {
  outline: none;
  border: 0;
  background: inherit;
  width: var(--input-width);
  height: var(--input-height);
  font-size: var(--font-size-300);
}
.wrapper input::-webkit-search-cancel-button{
  display: none;
}

.wrapper input::-moz-placeholder {
  color: var(--color-gray-light);
  font-family: var(--font-main);
}

.wrapper input::-webkit-input-placeholder {
  color: var(--color-gray-light);
  font-family: var(--font-main);
}

.wrapper input:-ms-input-placeholder {
  color: var(--color-gray-light);
  font-family: var(--font-main);
}

.wrapper input::-ms-input-placeholder {
  color: var(--color-gray-light);
  font-family: var(--font-main);
}

.wrapper input::placeholder {
  color: var(--color-gray-light);
  font-family: var(--font-main);
}

.wrapper.outline input {
  border-bottom: 2px solid var(--color-white);
  width: var(--input-width-sm);
  height: var(--input-height-sm);
  color: var(--color-white);
}

.wrapper.dark input {
  border: 1px solid var(--color-input-default);
  width: var(--input-width);
  height: var(--input-height);
  color: var(--color-white);
  padding-left: 25px;
}

.wrapper.light input {
  border: 1px solid var(--color-input-default);
  width: var(--input-width);
  height: var(--input-height);
  color: var(--color-default);
  background-color: var(--color-white);
  padding-left: 25px;
}

.wrapper.dark input:focus, .wrapper.light input:focus {
  border: 1px solid var(--color-input-hover);
  box-shadow: 0 0 4px 0 #c39b70;
}

.wrapper.dark.error input, .wrapper.light.error input {
  border: 1px solid var(--color-input-error);
}

.wrapper.light input {
  border: 1px solid var(--color-input-default);
  width: var(--input-width);
  height: var(--input-height);
  color: var(--color-default);
}

.input div.errorIcon {
  width: 30px!important;
  height: 30px!important;;
  border-radius: 100%;
  border: 2px solid var(--color-danger);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
}

.errorIcon:after {
  content: '!';
  color: var(--color-danger);
  font-size: var(--font-size-500);
}

.search {
  position: absolute;
  top: 50%!important;
  right: 0!important;
  transform: translateY(-50%)!important;
}

.wrapper.light .search svg path, .wrapper.outline .search svg path {
  fill: var(--color-input-default);

}
.wrapper.dark .search svg path {
  fill: var(--color-input-default);
}

@media (max-width: 1024px) {
  .input {
    width: 100%!important;
  }
}
