@import "../../styles/core/variables.css";

.wrapper {
  width: 100%;
  height: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  transform: rotate(45deg);
  margin: 0 73px !important;
}

.button.left svg {
  transform: rotate(-45deg);
}

.button.right svg {
  transform: rotate(-45deg);
}

.link {
  margin: 0 16px !important;
}

.dots {
  margin: 0 20px;
}

@media (max-width: 1280px) {

  .button {
    margin: 0 40px !important;
  }
  .link {
    margin: 0 8px !important;
  }
  .dots {
    margin: 0 8px !important;
  }
}

@media (max-width: 420px) {
  .button {
    display: none;
  }
}