@import "../../../styles/core/variables.css";

.section + .section {
  padding-top: var(--indent-md);
}


/* TODO: унифицировать */
.title {
  margin-bottom: var(--indent-xs);
}

.section.primary {
  padding: var(--indent-xs) 0;
}

/* TODO: унифицировать */
.section.primary + .section {
  border-top: 1px solid var(--color-gray);
}

.section.primary.dark {
  border: none;
}
