@import "../../../../assets/styles/core/variables.css";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--color-dark);
  width: 100%;
  height: 774px;
  max-height: 774px;
  position: relative;
  overflow: hidden;
}

.inner {
  width: 100%;
  padding: 56px calc(calc(100% - var(--grid-100)) / 2) 44px;
  z-index: 5;
  min-height: 100%;
}

.slider {
  position: relative;
}

.pagination {
  width: 100%;
  padding: 0 calc((calc(100% - var(--grid-100)) / 2) + 110px);
  justify-content: flex-start;
  bottom: 116px !important;
}

.border {
  width: 100%;
  min-height: 100%;
  padding: 80px 110px 142px;
  border: 1px solid rgba(255, 255, 255, 0.32);
}

.background, .backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.backgroundVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100vmin;
  min-height: 100vmin;
  transform: translate(-50%, -50%);
}

.backgroundVideo:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: rgba(51, 51, 51, 0.62);
}

.backgroundVideo iframe {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  width: auto!important;
  height: auto!important;
}
.info {
  padding: 19px 17px;
  background-color: var(--color-light-brown);
  font-size: var(--font-size-350);
  font-weight: 500;
  line-height: 0.59;
  letter-spacing: 1px;
  color: var(--color-white);

  display: block;
  width: fit-content;
}

.title {
  padding-top: 73px;
  margin-bottom: 60px;
}

.date {
  height: 60px;
  position: relative;
}

.date:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 34px;
  height: 3px;
  background: var(--color-white);
}

.text {
  color: var(--color-white);
}

@media (max-width: 1280px) {
  .wrapper {
    min-height: 75vh;
    height: 75vh;
    box-sizing: content-box;
  }
  .inner {
    padding: 10px 5px;
  }
  .border {
    padding: 67px 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .title > * {
    font-size: 32px;
    line-height: 1.25;
  }
  .info > * {
    font-size: 16px;
    font-weight: 500;
    line-height: 0.63;
    letter-spacing: 0.94px;
  }
  .date > * {
    font-size: 21px;
  }
  .pagination {
    justify-content: center;
    bottom: 29px!important;
  }

  .backgroundVideo iframe {
    min-height: 1300px;
    min-width: 100%;
  }
}


@media (max-width: 1024px) {
  .backgroundVideo iframe {
    height: 250vh !important;
    width: 250vh !important;
  }
}