.hasClose {
	box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.13);
	width: 326px;
	background-color: #ffffff;
	border-radius: 5px;
	padding: 12px 24px;
}

.hasClose > div > * {
	font-family: Muller;
	font-weight: 300;
	font-size: 16px;
}

.close {
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
	border-radius: 50%;
	border: 1px solid #000000;
	position: absolute;
	width: 20px;
	height: 20px;
	font-size: 0.7rem;
	line-height: 20px;
	text-align: center;
	text-shadow: none;
	color: black;
	opacity: .4;
	cursor: pointer;
}

.close:hover, .close:focus {
	opacity: 1;
}

.close:before, .close:after {
	position: absolute;
	left: 9px;
	top: 4px;
	content: ' ';
	height: 11px;
	width: 1px;
	background-color: currentColor;
}

.close:active {
	opacity: .2;
}

.close:before {
	transform: rotate(45deg);
}

.close:after {
	transform: rotate(-45deg);
}

.error {
	border: 1px solid red;
}

.info {
	border: 1px solid blue;
}

.success {
	border: 1px solid green;
}

.warning {
	border: 1px solid orange;
}
