@import "../../../../assets/styles/core/variables.css";

.root {
  width: var(--grid-100);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: auto;
  position: relative;
}

@media (max-width: 1280px) {
  .root {
    width: 100%;
  }
}