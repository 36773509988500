@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: 100%;
  position: relative;
  padding: 162px 0;
  background-color: var(--color-white);
}

.item {
  width: var(--history-width);
  height: var(--history-height);
  flex-direction: column;
  text-align: center;
  position: relative;
}

.item.center {
  background-color: var(--color-white);
}

.item:before {
  content: '';
  width: var(--history-rectangle-width);
  height: var(--history-rectangle-height);
  opacity: 0.37;
  background-color: var(--color-simply-white);
  position: absolute;
  margin-bottom: 60px;
  top: 0;
}

.item.center:before {
  width: calc(1.2 * var(--grid-100));
  left: 50%;
  transform: translateX(-50%);
}

.item.left:before {
  left: 0
}

.item.right:before {
  right: 0
}


.inner {
  width: var(--grid-100);
  height: var(--history-height);
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

.background {
  position: absolute;
  bottom: 0;
  width: var(--history-background-width);
  height: var(--history-background-height);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.item.center .background {
  right: 0;
  top: 182px;
}

.item.left .background {
  right: 0
}

.item.right .background {
  left: 0
}


.background:before {
  content: '';
  position: absolute;
  top: -12px;
  left: -12px;
  width: calc(var(--history-background-width) + 24px);
  height: calc(var(--history-background-height) + 24px);
  border: 1px solid #ececec;
}


.icon {
  width: var(--history-icon-width);
  height: var(--history-icon-height);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 15%;
}

.item.left .icon {
  left: 0
}

.item.right .icon {
  right: 0
}

.info {
  width: 47%;
  text-align: left;
  padding-top: 103px;
}

.item.left .info {
  margin-right: 53%;
}

.item.right .info {
  margin-left: 53%;
}

.item .title {
  color: var(--color-default);
  padding-bottom: 24px;
}

.item .description {
  color: var(--color-default);
}


@media (max-width: 1280px) {
  .wrapper {
    padding: 0;
    width: 100vw;
    padding-bottom: 120px;
  }

  .inner {
    width: 100vw;
    height: auto;
    padding: 45px 0;
    background-color: #e1e1e1;
  }

  .item {
    height: auto;
    margin-bottom: 72px;
  }

  .item:before {
    display: none;
  }

  .background {
    position: static;
    width: 100%;
    height: 207px;
  }

  .left .background {
    width: 250px;
    height: 177px;
    position: absolute;
    bottom: -90px;
  }

  .background:before {
    display: none;
  }

  .info {
    width: 100%;
    padding: 0 19px;
    padding-top: 60px;
  }

  .right .info {
    padding-top: 28px;
  }

  .left .info {
    padding-top: 0;
  }

  .item.right .info {
    margin-left: 0;
    width: 100%;
  }

  .item.left .inner {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 120px;
  }

  .item .icon {
    position: absolute;
    bottom: 15px;
  }

  .item.right .icon {
    position: absolute;
    bottom: -48px;
    right: 55px;
    width: 94px;
    height: 83px;
    background-size: contain;
  }

  .item.left .icon {
    position: absolute;
    bottom: -40px;
    left: 20px;
    width: 73px;
    height: 72px;
    background-size: contain;
  }

  .item.left .info {
    margin-right: 0;
    width: 100%;
  }

  .title {
    font-family: var(--font-header);
    font-size: var(--font-size-600);
    line-height: 1.33;
    color: var(--color-info) !important;
  }

  .description {
    opacity: 0.78;
    font-family: var(--font-main);
    font-size: var(--font-size-300);
    line-height: 1.56;
    color: var(--color-default) !important;
  }

  .isInner {
    display: flex;
    flex-direction: column-reverse;
    padding: 24px 19px 0;
    background-color: rgba(225, 225, 225, 0.22);
  }

  .item .isInner .icon {
    position: relative;
    bottom: 0;
    left: 0;
    width: 162px;
    height: 162px;
    margin: 30px 0;
    background-size: contain;
  }

  .item .isInner .background {

  }

  .item .isInner .info {
    padding: 0;
  }
}