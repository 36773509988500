@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: 180px;
  height: 166px;
  border: solid 1px #c39b70;
  position: relative;
}

.wrapper .avatar {
  width: 104px;
  height: 104px;
  border: solid 5px #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 26px;
  height: 26px;
}

.wrapper svg.icon path {
  fill: var(--color-primary);
}


@media (max-width: 1280px) {
  .wrapper {
    width: 130px;
    height: 130px;
  }

  .wrapper .avatar {
    width: 83px;
    height: 83px;
    border: solid 5px #ffffff;
  }
}