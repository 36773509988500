@import "../../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  height: 381px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.root.big {
  height: 507px;
}

.title.smallTitle {
  padding: 18px 100px;
  border-bottom: solid 1px rgba(235, 235, 235, 0.35);
}

.title>* {
  color: var(--color-white) !important;
}

@media (max-width: 1280px) {
  .root {
    height: 352px;
    padding: 0 22px;
  }

  .title>* {
    text-align: center;
    font-size: var(--font-size-600);
    line-height: 1.25;
  }
}