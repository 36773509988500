@import "../../../../assets/styles/core/variables.css";

.wrapper {
  height: 100vh;
}

.content {
  height: auto;
  min-height: calc(100vh - var(--size-header-height) - var(--size-footer-height));
}
