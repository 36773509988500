@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: var(--grid-100);
  margin: auto;
  border: solid 1px #d6daed;
  background-color: #ffffff;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.leftBlock {
  width: 820px;
  height: 528px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 4px;
  background-image: linear-gradient(to bottom, #fbfbfb, #eeeeee);
  position: relative;
}

.backgroundBlock {
  margin: 19px auto;
  width: 387px;
  height: 387px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: background-image 0.2s ease-in-out;
}

.imagesBlock {
  width: 83px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.imageBlock {
  width: 81px;
  height: 60px;
  box-shadow: 0 22px 24px 0 rgba(195, 155, 112, 0.11);
  border: solid 1px rgba(0, 0, 0, 0);
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-bottom: 1px;
}

.imageBlockActive {
  border: solid 1px #c39b70;
  position: relative;
}

.imageBlockActive:after {
  content: '';
  background: url("./info.svg");
  width: 12px;
  height: 12px;
  position: absolute;
  top: 2px;
  right: 2px;
}

.catalogBlock {
  width: 81px;
  height: 73px;
  box-shadow: 0 22px 24px 0 rgba(195, 155, 112, 0.11);
  border: solid 1px rgba(0, 0, 0, 0);
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-bottom: 1px;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.catalogBlockIcon {
  width: 22px;
  height: 29px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}


.imageMiniWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-flow: wrap;
}

.imageMini {
  width: 73px;
  height: 73px;
  background-color: #ffffff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin-right: 4px;
}


.rightBlock {
  width: 452px;
  height: 536px;
  border-left: solid 1px #d6daed;
}

.nameBlock {
  width: 100%;
  height: 86px;
  padding: 18px;
  border-bottom: solid 1px #d6daed;
}

.name {
  color: var(--color-info) !important;
}

.finished {
  color: var(--color-input-error) !important;
  text-align: center;
  padding-top: 14px;
}

.lot {
  font-size: 17px;
  font-weight: bold;
  line-height: 1.29;
  color: #c39b70;
  padding-top: 8px;
}

.priceWrapper {
  padding: 35px 95px;
  position: relative;
}

.priceWrapper .form {
  min-width: 100%;
  align-items: flex-start;
}

.control {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 10px;
}

.control .search {
  margin: 0;
  border-radius: 50%;
}

.search:after {
  border: 0 !important;
}

.sendText {
  font-size: 18px;
  line-height: 1.22;
  width: 100% !important;
  min-width: 100% !important;
}

.warning {
  color: var(--color-danger);
}

.priceText {
  height: 60px;
  border: solid 1px rgba(51, 66, 91, 0.49);
  margin: 10px auto 15px;
  font-family: var(--font-header);
  font-size: 30px;
  line-height: 60px;
  padding-left: 13px;
  color: var(--color-info);
  width: 100% !important;
  min-width: 100% !important;
}

.send {
  width: 100% !important;
  min-width: 100% !important;
  height: 60px;
  padding: 0px 0px !important;
}

.loaded {
  filter: blur(3px);
}

.loading {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.tooltip {}

.loadingElement {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 16px;
}

.loadingElement div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: 2px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: loadingElement 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loadingElement div:nth-child(1) {
  animation-delay: -0.45s;
}

.loadingElement div:nth-child(2) {
  animation-delay: -0.3s;
}

.loadingElement div:nth-child(3) {
  animation-delay: -0.15s;
}

.video {
  margin-top: 19px;
  width: 262px;
  height: 202px;
  box-shadow: 0 17px 25px 15px rgba(0, 0, 0, 0.1);
  background-color: #dbdbdb;
  background-image: url("./video.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.exit {}

.fullscreen {
  margin-right: 19px;
}

.fullscreenIcon {
  width: 19px;
  height: 19px;
  display: inline-block;
  background-image: url("./fullscreen.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  top: 4px;
}

.fullscreen.open .fullscreenIcon {
  background-image: url("./normalscreen.svg");
}


.exitIcon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-image: url("./exit.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  top: 3px;
  margin-right: 6px;
}

@keyframes loadingElement {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.chatWrapper {
  position: absolute;
  top: 50%;
  right: 40px;
  width: 0;
  height: 0;
  z-index: 300;
}

.chatInner {
  position: absolute;
  right: 0;
  width: content-box;
  height: content-box;
  z-index: 4;
}

.chatInnerHide {
  width: 0;
  height: 0;
  overflow: hidden;
}

.chatIcon {
  width: 59px;
  height: 44px;
  background-image: url("./chatClose.svg"), url("./chatOpen.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 0;
  right: -59px;
  z-index: 5;
}

.chatIconOpen {
  background-image: url("./chatOpen.svg");
}

.finishDescription {
  padding-top: 24px;
}

.finishLine {
  margin-top: 32px;
  opacity: 0.33;
  background-color: #979797;
}

.finishInner {}

.finishButton {
  width: 268px;
  height: 60px;
}

@media (min-width: 1280px) {
  .chatInner {
    top: 0;
  }
}
@media (max-width: 1280px) {
  .wrapper {
    padding: 0 8px;
    margin-top: 0;
    width: 100%;
    flex-direction: column;
  }

  .leftBlock {
    width: 100%;
    height: 245px;
    align-items: flex-start;
  }

  .rightBlock {
    width: 100%;
    height: 290px;
  }

  .control {
    top: auto;
    bottom: 0;
  }

  .fullscreen {
    display: none;
  }

  .backgroundBlock {
    height: 222px;
    width: 100%;
    margin: 8px 0;
  }

  .imagesBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    top: auto;
    bottom: -435px;
    z-index: 1001;
  }

  .name {
    font-size: var(--font-size-200);
  }

  .nameBlock {
    height: auto;
    padding: 8px;
  }

  .lot {
    font-size: var(--font-size-200);
  }

  .priceWrapper {
    padding: 0 6px;
    display: flex;
    flex-direction: column-reverse;
  }

  .priceText {
    width: calc(50% - 2.5px) !important;
    min-width: 0 !important;
    margin: 14px 0;
    font-size: 22px;
  }

  .video {
    width: calc(50% - 2.5px);
    height: auto;
    min-height: 139px;
    margin-top: 7px;
  }

  .send {
    width: calc(50% - 2.5px) !important;
    min-width: 0 !important;
    margin: 14px 0;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .sendText {
    width: calc(50% - 2.5px) !important;
    min-width: 0 !important;
    margin: 14px 0;
    position: absolute;
    right: 0;
    bottom: 74px;
  }
  .chatWrapper {
    position: fixed;
    top: 96%;
    z-index: 999999;
  }

  .chatInner {
    bottom: 63px;
    right: -37px;
  }
  .sendText {
    display: none;
  }
  .catalogBlock {
    display: none;
  }
}