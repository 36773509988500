@import "~auction.core/assets/styles/core/fonts.css";
@import "~auction.core/assets/styles/core/normalize.css";
@import "~auction.core/assets/styles/core/swiper.css";
@import "~auction.core/assets/styles/core/variables.css";

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
	"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

.wrapperForm {
	min-width: 370px!important;
}

.loading {
	display: inline-block;
	width: 30px;
	height: 30px;
	border: 2px solid rgba(255,255,255, 0.2);
	border-radius: 50%;
	border-top-color: #922c88;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
	left: calc(50% - 15px);
	top: calc(50% - 15px);
	position: fixed;
	z-index: 1;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
