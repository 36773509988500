@import "../../styles/core/variables.css";

.wrapper {
  position: relative;
}


.trigger {
  display: inline-block;
  text-decoration: underline;
}

.bubble {
  min-width: 250px;
  max-width: 328px;
  position: absolute;
  z-index: 10;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--color-white);
}

.bubble::after {
  content: '';
  position: absolute;
  transform: rotate(45deg) translate(-50%, 15%);
  transform-origin: center;
  width: 25px;
  height: 25px;
  background-color: var(--color-white);
}

.top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 9px;
  transform: translateX(-50%);
}

.top::after {
  top: calc(100% - 14px);
  left: 50%;
}

.stateLeft.top {
  left: -22px;
  transform: translateX(0);
}

.stateLeft.top::after {
  left: 35px;
}

.stateRight.top {
  left: unset;
  right: -22px;
  transform: translateX(0);
}

.stateRight.top::after {
  left: calc(100% - 35px);
}

.bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.bottom::after {
  top: 0;
  left: 50%;
}

.stateLeft.bottom {
  left: -22px;
  transform: translateX(0);
}

.stateLeft.bottom::after {
  left: 35px;
}

.stateRight.bottom {
  left: unset;
  right: -22px;
  transform: translateX(0);
}

.stateRight.bottom::after {
  left: calc(100% - 35px);
}

.left {
  top: 50%;
  right: 100%;
  margin-right: 9px;
  transform: translateY(-50%);
}

.left::after {
  top: 50%;
  left: 0;
}
.right {
  top: 50%;
  left: 100%;
  margin-left: 9px;
  transform: translateY(-50%);
}

.right::after {
  top: 50%;
  left: 100%;
}

.stateLeft.right {
  transform: translateY(-35px);
}

.stateLeft.right::after {
  left: 5px;
  top: 15px;
}


.message {
  color: var(--color-default);
  padding: 8px 14px;
  position: relative;
  z-index: 2;
}