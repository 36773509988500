@import "../../styles/core/variables.css";

.wrapper {
  display: inline-block;
  vertical-align: middle;
  width: var(--item-width);
  height: var(--item-height);
  background-color: inherit;
  border: solid 2px var(--color-item-border);
  cursor: pointer;
  padding: 21px;
  text-align: center;
  transition: all .2s;
  position: relative;
}

.wrapper.max {
  height: var(--item-height-max);
}

.wrapper.line {

}

.wrapper:hover {
  box-shadow: 0 22px 24px 0 rgba(195, 155, 112, 0.11);
  border: solid 2px var(--color-border-button);
  background-color: var(--color-white);
}


.image {
  width: 248px;
  height: 220px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.headerText {

}

.text {
  padding-top: 10px;
  color: var(--color-default);
}

.text.textLight {
  color: var(--color-default);
}

.dark {

}

.dark.wrapper {
  background-color: #f3f3f3;
  border: solid 2px #f3f3f3;
}

.dark.wrapper:hover {
  background-color: var(--color-info);
  box-shadow: none;
  border: solid 2px var(--color-info);
}

.dark .text {
  color: var(--color-info);
}

.dark.wrapper:hover .text {
  color: var(--color-white);
}

.archive {
  padding: 0;
  width: 365px;
  height: 726px;
}

.archive.wrapper {
  background-color: rgba(0, 0, 0, 0);
  border: solid 2px rgba(0, 0, 0, 0);
}

.archive.wrapper .image {
  width: 100%;
  height: 550px;
}

.archive.wrapper:hover {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
  border: solid 2px rgba(0, 0, 0, 0);
}

.archive .text {
  color: var(--color-info);
}

.archive.wrapper:hover .text {

}

.dateTitle {
  padding: 8px 0 16px;
}

.date {

}

.headerTitle {
  min-height: 76px;
}

.priceWrapper {
  width: 100%;
  height: 48px;
  background-position: 50%;
  background-repeat: repeat-y;
  background-size: contain;
  margin: 15px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.priceText {
  font-weight: 500;
  line-height: 1.3;
  color: var(--color-white);
}

.price {
  font-weight: bold;
  line-height: 1.3;
  color: var(--color-white);
}

.whiteColorPrice {}


.whiteColorPrice .priceText {
  color: #9b9b9b;
}

.whiteColorPrice .price {
  color: var(--color-default);
}

.redColorPrice {
  background-image: url("./redLine.svg");
}

.blueColorPrice {
  background-image: url("./blueLine.svg");
}

.greenColorPrice {
  background-image: url("./greenLine.svg");
}

.catalogWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.catalog {
  width: 198px;
  height: 60px;
  padding: var(--indent-lg) var(--indent-lg)!important;
}
.pdf {
  padding: 0!important;
}
.pdf svg {
  width: 44px;
  height: 50px;
}

.loaderWrapper {
 position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(193, 156, 112, 0.78);
}

.loader {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader div:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  transform: translate(-50%, -50%);
  background-color:  #fff;
  opacity: 1;
  border-radius: 50%;
}
.loader div:nth-child(2), .loader div:nth-child(3) {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loader div:nth-child(3) {
  animation-delay: -1s;
}

.loadingText {
  font-size: 20px;
  position: absolute;
  top: 65%;
  width: 100%;
  color: var(--color-white)
}

@keyframes loader {
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.badge {
  background-color: rgb(193, 156, 112);
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 15px;
}
.badgeText {
  color: var(--color-white)
}


@media (max-width: 1280px) {
  .wrapper {
    width: 100%;
    height: auto;
    min-height: 182px;
    border: solid 2px var(--color-white);
    background-color: #f3f3f3;
  }
  .image {
    width: 107px;
    height: 118px;
    margin: auto;
  }
  .headerTitle {
    height: auto;
    min-height: 0;
  }
  .text {
    font-family: var(--font-header);
    font-size: 12px;
    line-height: 1.27;
    text-align: center;
    color: var(--color-info);
  }
}