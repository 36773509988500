@import "../../../../assets/styles/core/variables.css";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 calc(calc(100% - var(--grid-100)) / 2);
  background-color: transparent;
  position: relative;
}

.header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 40px 0;
  margin-bottom: 40px;
  border-bottom: solid 1px rgba(152, 209, 189, 0.33);
}

.form {
  width: 503px;
}

.headerWrapper {
  padding-left: 40px;
}

.headerDescription {
  max-width: 609px;
}

.headerDescription > * {
  line-height: 1.56 !important;
  opacity: 0.78;
}

.headerTitle {
  padding-bottom: 22px;
}


@media (max-width: 1280px) {
  .wrapper {
    padding: 0 10px;
  }
  .form {
    width: 100%;
  }
}