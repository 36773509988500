@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: 100%;
  height: var(--size-advantages-height);
  position: relative;
}

.inner {
  width: 100%;
  padding: 0 calc(calc(100% - var(--grid-100)) / 2);
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2vw;
  z-index: 1;
  position: relative;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: var(--advantage-width);
  height: var(--advantage-height);
  flex-direction: column;
  text-align: center;
  padding-top: 67px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.icon {
  width: var(--advantage-icon-width);
  height: var(--advantage-icon-height);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.title {
  padding: 39px 0 35px;
  position: relative;
}

.title:before {
  content: '';
  position: absolute;
  bottom: 18px;
  left: 50%;
  width: 20px;
  height: 2px;
  transform: translateX(-50%);
  background: var(--color-light-brown);
}

.description {

}


@media (max-width: 1280px) {
  .wrapper {
    height: auto;
  }
  .inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    padding-bottom: 32px;
  }
  .item {
    height: auto;
    padding-top: 32px;
    padding-bottom: 22px;
  }
}