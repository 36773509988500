@import "../../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  height: 100%;
  display: flex;

}

.inner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
  padding: 12px 0;
}
