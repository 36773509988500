@import "../../../../assets/styles/core/variables.css";

.wrapper {
  padding: 20px 60px;
  background-color: var(--color-white);
}


@media (max-width: 720px) {
  .wrapper {
    padding: 20px 19px;
  }
}