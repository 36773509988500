@import "../../../../assets/styles/core/variables.css";

.wrapper {
  padding: 20px 60px;
  background-color: var(--color-white);
}

.button {
	margin: 0 auto;
	display: block;
	border: 1px solid var(--color-primary);
}

@media (max-width: 1280px) {
  .wrapper {
    padding: 0;
    width: 100vw;
  }
}
