@import "../../../assets/styles/core/variables.css";

.wrapper {
  width: var(--grid-100);
  margin: 110px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 50px;
  padding: 24px;
  text-align: center;
  line-height: 1.1;
  color: var(--color-info);
}

.description {
  text-align: center;
  color: var(--color-info);
  max-width: 825px;
  margin: 12px auto 65px;
}

.go {
  margin: auto;
  width: 280px;
  height: 60px;
  font-size: 16px;
  line-height: 0.63;
  color: var(--color-default);
}

.image {
  width: 728px;
  height: 388px;
  background-image: url("./404.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media (max-width: 1280px) {
  .wrapper {
    margin: 78px auto 58px auto;
    height: auto;
    width: auto;
  }

  .title {
    font-size: 26px;
    padding: 19px 12px;
  }

  .description {
    font-size: var(--font-size-300);
    line-height: 1.6;
    margin: 0 auto 31px;
    padding: 0 12px;
    opacity: 0.78;
    font-weight: normal;
    width: 100%;
  }

  .image {
    width: 100%;
    background-size: contain;
    background-position-y: bottom;
  }
}