@import "../../styles/core/variables.css";

.wrapper {
  width: 100%;
}

.slider {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}