@import "../../../assets/styles/core/variables.css";

.wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(51, 66, 91, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transform: translateY(-50%) scaleX(1) scaleY(0);
  transition: transform .4s;
}

.show {
  transform: translateY(0) scale(1) scaleY(1);
}

.form {
  width: 911px;
  display: flex;
  flex-direction: column;
}

.field {
  margin: auto;
  margin-bottom: 0!important;
  padding-bottom: 70px!important;
}

.label div {
  font-family: var(--font-header);
  font-size: 40px!important;
  line-height: 0;
  color: var(--color-white);
}

.inputWrapper {
  margin: 0 auto;
}

.input {
  width: 911px !important;
  font-size: 40px!important;
  height: 80px !important;
}

.search {
  width: 80px !important;
  height: 80px !important;
}

.search svg {
  width: 26px;
  height: 26px;
}

.wrapper .inputWrapper input.input::-moz-placeholder {
  color: var(--color-white);
  font-family: var(--font-header)!important;
}

.wrapper .inputWrapper input.input::-webkit-input-placeholder {
  color: var(--color-white);
  font-family: var(--font-header)!important;
}

.wrapper .inputWrapper input.input:-ms-input-placeholder {
  color: var(--color-white);
  font-family: var(--font-header)!important;
}

.wrapper .inputWrapper input.input::-ms-input-placeholder {
  color: var(--color-white);
  font-family: var(--font-header)!important;
}

.wrapper .inputWrapper input.input::placeholder {
  color: var(--color-white);
  font-family: var(--font-header)!important;
}
.wrapper .inputWrapper input.input:focus::-webkit-input-placeholder { color:transparent; }
.wrapper .inputWrapper input.input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
.wrapper .inputWrapper input.input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
.wrapper .inputWrapper input.input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */