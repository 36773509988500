@import "../../styles/core/variables.css";

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.05);
}
