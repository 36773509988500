@import "../../styles/core/variables.css";

.ripple {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 64px;
  height: 64px;
}

.ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.rippleItem {
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}


@keyframes spinner--container {
  0% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  16.666% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
  }
  33.333% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
  49.999% {
    -webkit-transform: rotate(-150deg);
    transform: rotate(-150deg);
  }
  66.666% {
    -webkit-transform: rotate(-210deg);
    transform: rotate(-210deg);
  }
  83.333% {
    -webkit-transform: rotate(-270deg);
    transform: rotate(-270deg);
  }
  100% {
    -webkit-transform: rotate(-330deg);
    transform: rotate(-330deg);
  }
}

@keyframes spinner--before {
  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
}

@keyframes spinner--after {
  100% {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
}

.spinner {
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
  -webkit-animation: spinner--container 3s steps(1) infinite;
  animation: spinner--container 3s steps(1) infinite;
}

.spinner:before, .spinner:after {
  content: '';
  display: block;
  border-bottom: 60px solid #344c69;
  border-left: 34.5px solid transparent;
  border-right: 34.5px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -69px;
  margin-top: -60px;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-animation: spinner--before 0.5s linear infinite;
  animation: spinner--before 0.5s linear infinite;
}

.spinner:after {
  -webkit-animation-name: spinner--after;
  animation-name: spinner--after;
}