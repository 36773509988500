@import "../../../../assets/styles/core/variables.css";

.root {
  width: 290px;
  padding-top: 40px;
  padding-bottom: 62px;
  margin-right: 40px;
}

.menu {
  width: 290px;
  height: fit-content;
  border-radius: 3px;
  background-color: var(--color-info);
}


@media (max-width: 1280px) {
  .root {
    display: none;
  }
}