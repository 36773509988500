@import "../../../assets/styles/core/variables.css";

.root {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: auto;
  background-color: var(--color-white-background);
}

.isGrid {
  width: var(--grid-100);
}
