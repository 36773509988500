@import "../../../../assets/styles/core/variables.css";

.title {
  font-family: var(--font-header);
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-info);
  margin: 48px 0;
}

.button {
  width: 268px;
  height: 60px;
  margin: 24px auto 68px;
  display: block;
}

.arrow {
  transform: rotate(45deg);
  margin: 0 !important;
}

.arrow.left svg {
  transform: rotate(-45deg);
}

.arrow.right svg {
  transform: rotate(-45deg);
}

.slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.wrapper {
  display: block;
  width: 280px;
  overflow: hidden;
}

.inner {
  display: block;
}

.skin {
  width: 280px;
  height: 323px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}

.skinDefault {
  background-image: url("./assets/skin1_full.svg");
}

.skin1 {
  background-image: url("./assets/skin1_full.svg");
}

.skin2 {
  background-image: url("./assets/skin2_full.svg");
}

.skin3 {
  background-image: url("./assets/skin3_full.svg");
}

.skin4 {
  background-image: url("./assets/skin4_full.svg");
}

.skin5 {
  background-image: url("./assets/skin5_full.svg");
}

.skin6 {
  background-image: url("./assets/skin6_full.svg");
}

.skin7 {
  background-image: url("./assets/skin7_full.svg");
}

.skin8 {
  background-image: url("./assets/skin8_full.svg");
}

.skin9 {
  background-image: url("./assets/skin9_full.svg");
}

.skin10 {
  background-image: url("./assets/skin10_full.svg");
}

.skin11 {
  background-image: url("./assets/skin11_full.svg");
}

.skin12 {
  background-image: url("./assets/skin12_full.svg");
}

.skin13 {
  background-image: url("./assets/skin13_full.svg");
}

.skin14 {
  background-image: url("./assets/skin14_full.svg");
}