@import "../../../../assets/styles/core/variables.css";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--color-footer-slider);
  width: 100%;
  position: relative;
}

.inner {
  width: 100%;
  padding: 0 calc(calc(100% - var(--grid-100)) / 2);
  margin-bottom: 123px;
  z-index: 1;
  min-height: 471px;
  position: relative;
  display: flex;
  justify-content: center;
}

.innerNotSlider {
  border: solid 1px rgba(151, 151, 151, 0.26);
}

.slider {
  position: relative;
}

.pagination {
  width: 100%;
  padding: 0 calc((calc(100% - var(--grid-100)) / 2) + 110px);
  bottom: 73px !important;
}

.border {
  width: 50%;
  padding: 58px 83px;
  position: relative;
  min-height: 471px;
}

.left {
  background-color: var(--color-white);
}

.right {
  background-color: var(--color-info);
}

.background {
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.info {
  font-size: var(--font-size-250);
  font-weight: 500;
  line-height: 0.67;
  letter-spacing: 1.76px;
  color: var(--color-orange);
}

.title {
  padding-top: 2px;
  padding-bottom: 24px;
  font-size: 24px;
  line-height: 1.67;
  color: var(--color-white);
  border-bottom: solid 1px rgba(151, 151, 151, 0.15);
  margin-bottom: 19px;
}

.description {
  height: 60px;
  position: relative;
}

.text {
  color: var(--color-white);
}

.textInfo {
  color: var(--color-info);
}

.headerTitle {
  text-align: center;
  margin: 60px 0 51px;
}


@media (max-width: 1280px) {
  .innerNotSlider.inner {
    width: 100%;
    height: auto;
    min-height: 182px;
    background-color: #ffffff;
    border: solid 2px var(--color-item-border);
    flex-direction: column;
    padding: 21px;
  }

  .innerNotSlider .background {
    width: 107px;
    height: 118px;
    margin: auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .innerNotSlider .border {
    width: 100%;
    padding: 0;
    min-height: auto;
    background-color: rgba(0,0,0,0);
  }

  .innerNotSlider .info {
    font-size: var(--font-size-250);
    font-weight: 500;
    line-height: 0.67;
    letter-spacing: 1.76px;
    color: var(--color-orange);
  }

  .innerNotSlider .description {
    height: 60px;
    position: relative;
  }

  .innerNotSlider .info {
    display: none;
  }

  .innerNotSlider .title {
    font-family: var(--font-main);
    font-size: 13px;
    line-height: 1.38;
    text-align: center;
    color: var(--color-default);
    border-bottom: 0;
    padding: 0;
    margin-bottom: 0;
    padding-top: 10px;
  }

  .innerNotSlider .description {
    display: none;
  }

  .innerNotSlider .headerTitle {
    height: auto;
    min-height: 0;
  }
  .innerNotSlider .text {
    display: none;
  }

  .innerNotSlider {
    margin-bottom: 0;
    margin-top: 0;
  }
}


@media (max-width: 1024px) {
  .innerSlider {
    flex-direction: column;
  }
  .innerSlider .left {
    width: 100%;
    height: 40vh;
    padding: 32px 27px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innerSlider .left .background {
    width: 100vw;
    height: 40vh;
    min-width: 100%;
    min-height: 100%;
  }
  .innerSlider .right {
    width: 100%;
    padding: 32px 15px;
    min-height: auto;
  }
  .innerSlider .right .description {
    height: auto;
  }
}