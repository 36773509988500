@import "../../../styles/core/variables.css";

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  color: var(--color-gray);
  font-family: var(--font-header);
  font-weight: var(--font-weight-normal);
}

.dark {
  color: var(--color-white);
}

.h1 {
  font-size: var(--font-size-900);
  line-height: var(--line-height-900);
}

.h2 {
  font-size: var(--font-size-800);
  line-height: var(--line-height-900);
}

.h3 {
  font-size: var(--font-size-700);
  line-height: var(--line-height-700);
}

.h4 {
  font-size: var(--font-size-500);
  line-height: var(--line-height-400);
}

.h5 {
  font-size: var(--font-size-400);
  line-height: var(--line-height-500);
}

.h6 {
  font-size: var(--font-size-300);
  line-height: var(--line-height-500);
}

.textCaption {
  font-size: var(--font-size-600);
  line-height: var(--line-height-800);
}

.textPrimary {
  font-size: var(--font-size-200);
  line-height: var(--line-height-500);
}

.textSecondary {
  font-size: var(--font-size-200);
  line-height: var(--line-height-600);
}

/** states */
.default {
  color: var(--color-default);
}

.muted {
  color: var(--color-muted);
}

.info {
  color: var(--color-info);
}

.primary {
  color: var(--color-primary);
}

.success {
  color: var(--color-success);
}

.warning {
  color: var(--color-danger);
}

.danger {
  color: var(--color-danger);
}

.bold {
  font-weight: var(--font-weight-bold);
}

.italic {
  font-style: italic;
}

.strike {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

/* dark theme */
.primary.dark,
.info.dark,
.muted.dark,
.default.dark {
  color: var(--color-dark-white);
}
