@import "../../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-white);
  position: relative;
  z-index: 1;
}

.inner {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
}

.input {
  width: 295px;
  min-height: 38px;
  border-radius: 4px;
  border: solid 1px rgba(151, 151, 151, 0.25);
  font-family: var(--font-main);
  font-size: 13px;
  outline: none;
  padding: 9px 11px;
  resize: none;
  line-height: 20px;
}

.bubble {
  position: absolute;
  display: block;
  margin-top: 1em;
}

.bubbleList {
  background-color: var(--color-white);
  border: solid 1px rgba(151, 151, 151, 0.25);
}

.icon {
  width: 22px;
  height: 22px;
  margin: 9px 13px 12px;
  cursor: pointer;
}

.button {
  background-image: url("./assets/sendButton.svg");
  width: 22px;
  height: 19px;
  margin: 10px 13px 14px 0;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}


@media (max-width: 1280px) {
  .input {
    max-width: 50vw;
  }
}