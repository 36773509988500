@import "../../../assets/styles/core/variables.css";

.wrapper {
  width: var(--grid-100);
  margin: 110px auto;
  border: solid 1px #c39b70;
  padding: 110px;
  position: relative;
}

.title {
  font-size: 50px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 40%;
  padding: 24px;
  text-align: center;
  background: var(--color-white);
  line-height: 1.1;
}

.chapter {
  padding: 24px 0 8px;
}

.rules {
  white-space: pre-wrap;
}

@media (max-width: 1280px) {
  .wrapper {
    width: 100%;
    padding: 68px 14px;
  }

  .chapter {
    font-size: 26px;
  }

  .title {
    width: 98%;
    font-size: 34px;
  }
}