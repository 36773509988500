@import "../../styles/core/variables.css";

:global(.Select) {
  color: #777;
  display: inline-block;
  font-size: 12px;
  height: 60px;
  position: relative;
  border: solid 1px #c39b70;
  background-color: #ffffff;
}

:global(.Select-selection) {
  border: solid 1px #b9c0c5;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 290px;
  outline: none;
  padding: 0 25px;
}

:global(.Select-selection):hover, :global(.Select-selection.is-focused) {
  border: solid 1px #7bbef7;
}

:global(.Select-selection):after {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 7px 5px 0;
  content: '';
  margin-top: -3.5px;
  position: absolute;
  right: 7px;
  top: 50%;
}

:global(.Select-dropdown) {
  background: #fff;
  border-radius: 4px;
  border: solid 1px #b9c0c5;
  box-sizing: border-box;
  left: 0;
  margin-top: 2px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
}

:global(.Select-dropdown.is-hidden) {
  visibility: hidden;
}

:global(.Select-option) {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  outline: none;
  overflow: hidden;
  height: 60px;
  padding: 7px 25px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

:global(.Select-option):first-of-type {
  border-radius: 4px 4px 0 0;
}

:global(.Select-option):last-of-type {
  border-radius: 0 0 4px 4px;
}

:global(.Select-option).is-selected {
  background: #f3f3f3;
}

:global(.Select-option).is-highlighted {
  background: #ddd;
}

:global(.Select-optionText) {
  box-sizing: border-box;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 10px;
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: normal;
  color: var(--color-default);
}
