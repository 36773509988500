@import "../../../assets/styles/core/variables.css";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1003;
}


.sideRight {
  justify-content: flex-end;
}

.sideCenter {
  justify-content: center;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(51, 66, 91, 0.9);
  z-index: 999;
}

.container {
  position: relative;
  z-index: 1001;
  border: solid 1px var(--color-item-border);
  outline: solid 1px var(--color-white);
  background-color: var(--color-white);
  overflow-y: auto;
  max-height: 100%;
}

.sideCenter .container {
  min-width: 632px;
}

.sideRight .container {
  height: 100%;
  width: 615px;
  padding: 86px 79px;

}

.content {
  padding: 0 131px;
}

.sideRight .content {
  padding: 0 0;
}

.sideCenter .content {
  padding: 0 131px;
}

.header {
  text-align: center;
}

.sideRight .header {
  text-align: left;
  padding-bottom: 18px;
}

.sideCenter .header {
  padding-top: 49px;
  padding-bottom: 49px;
  text-align: center;
}

.close {
  width: 26px;
  height: 26px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNiIgaGVpZ2h0PSIyNiIgdmlld0JveD0iMCAwIDI2IDI2Ij4gICAgPGcgZmlsbD0iI0E2QTZBNiIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgIDxwYXRoIGQ9Ik0xMyAwQzUuODMyIDAgMCA1LjgzMiAwIDEzYzAgNy4xNjkgNS44MzIgMTMgMTMgMTNzMTMtNS44MzEgMTMtMTNjMC03LjE2OC01LjgzMi0xMy0xMy0xM3ptMCAyNUM2LjM4MyAyNSAxIDE5LjYxNiAxIDEzIDEgNi4zODMgNi4zODMgMSAxMyAxYzYuNjE2IDAgMTIgNS4zODMgMTIgMTIgMCA2LjYxNi01LjM4NCAxMi0xMiAxMnoiLz4gICAgICAgIDxwYXRoIGQ9Ik0xNy44NTQgOC4xNDZhLjUuNSAwIDAgMC0uNzA3IDBMMTMgMTIuMjkzIDguODU0IDguMTQ3YS41LjUgMCAxIDAtLjcwNy43MDdMMTIuMjkzIDEzbC00LjE0NiA0LjE0NmEuNS41IDAgMSAwIC43MDcuNzA3TDEzIDEzLjcwN2w0LjE0NiA0LjE0NmEuNDk5LjQ5OSAwIDAgMCAuNzA3IDAgLjUuNSAwIDAgMCAwLS43MDdMMTMuNzA3IDEzbDQuMTQ2LTQuMTQ2YS41LjUgMCAwIDAgMC0uNzA3eiIvPiAgICA8L2c+PC9zdmc+);
  position: absolute;
  top: 27px;
  right: 18px;
  cursor: pointer;
}

@media (max-width: 1280px) {
  .wrapper {
    padding: 12px;
  }

  .container {
    min-width: 0 !important;
    width: 100%;
  }

  .content, .sideCenter .content {
    padding: 0 14px;
  }
}