@import "../../styles/core/variables.css";

.sm {
  width: 12px;
  height: 12px;
  max-width: none; /* глобальные стили ms.com портят иконки, небольшой хак */
}

.md {
  width: 16px;
  height: 16px;
  max-width: none; /* глобальные стили ms.com портят иконки, небольшой хак */
}

.l {
  width: 20px;
  height: 20px;
  max-width: none; /* глобальные стили ms.com портят иконки, небольшой хак */
}

.lg {
  width: 24px;
  height: 24px;
  max-width: none; /* глобальные стили ms.com портят иконки, небольшой хак */
}

.xs {
  width: 28px;
  height: 28px;
  max-width: none;
}

.xl {
  width: 36px;
  max-width: none; /* глобальные стили ms.com портят иконки, небольшой хак */
}

/* states */
/* TODO: убрать дублирование  */
.primary, .primary path:not(:global(.origin)) {
  fill: var(--color-primary)
}

.primary.darkTheme, .primary.darkTheme path:not(:global(.origin)) {
  fill: var(--color-white)
}

.default, .default path:not(:global(.origin)) {
  fill: var(--color-grey-400)
}

.default.darkTheme, .default.darkTheme path:not(:global(.origin)) {
  fill: var(--color-white)
}


.white, .white path:not(:global(.origin)) {
  fill: var(--color-white)
}

.success, .success path:not(:global(.origin)) {
  fill: var(--color-success)
}

.muted, .muted path:not(:global(.origin)) {
  fill: var(--color-muted)
}
