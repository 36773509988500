@import "../../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  display: flex;
}

.inner {
  width: 100%;
  height: auto;
  flex-direction: row;
  display: flex;
  padding-right: 60px;
  margin-bottom: 14px;
}
.inner.yourself {
  flex-direction: row-reverse;
  padding-left: 60px;
  padding-right: 0;
}
.avatar {
  padding: 0 13px;
}

.content {
  background-color: #f1f0f0;
  padding: 6px 13px 8px 14px;
  border-radius: 5px 25px 25px;
}

.yourself .content {
  background-color: #56698a;
  color: var(--color-white);
  padding: 8px 14px 6px 13px;
  border-radius: 25px 5px 25px 25px;
}

.content .text {
  padding-top: 0;
}

.content .text a {
	color: #56698a;
	text-decoration: underline;
}

.yourself .content .text a {
	color: var(--color-white);
}

.yourself .content .name {
  background-color: #56698a;
  text-align: right;
}
.yourself .content > * {
  color: var(--color-white);
}
