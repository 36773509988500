@import "../../styles/core/variables.css";

.wrapper {
  display: flex;
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
}
.text {
  font-family: var(--font-header);
  font-size: 46px;
  color: var(--color-info);
  text-align: center;
}