@import "../../../../assets/styles/core/variables.css";

.root {
  width: 947px;
  padding-top: 40px;
  padding-bottom: 62px;
  margin-right: 40px;
}

.menu {
  width: 290px;
  height: 468px;
  border-radius: 3px;
  background-color: var(--color-info);
}

.inner {
  width: 100%;
  padding: 20px;
  height: 567px;
  background-color: var(--color-white);
}

.controlInner {
  padding: 21px;
  background-color: rgba(225, 225, 225, 0.37);
  margin-bottom: 23px;
}

.title {
  margin-bottom: 9px;
}

.textInfo {
  font-weight: normal !important;
}

.options {

}

.select {
  width: 174px;
  margin-right: 16px;
}

.select > span {
  min-width: 174px;
}

.select > span > span {
  overflow: hidden;
  line-height: 18px;
}

.select > ul {
  width: fit-content;
  max-height: 300px;
  overflow: auto;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  flex-direction: column;
}

.empty:before {
  content: '';
  background-image: url(./empty.svg);
  width: 103px;
  height: 92px;
  object-fit: contain;
  margin-bottom: 80px;
}


@media (max-width: 1280px) {
  .root {
    padding: 0 10px;
    width: 100%;
    margin-right: 0;
  }

  .select {
    width: 120px;
  }

  .select > span {
    min-width: 120px;
  }

}