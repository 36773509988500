@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: 100%;
  min-height: 185px;
  background-color: rgba(225, 225, 225, 0.38);
  position: relative;
  padding: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.time {
  width: 98px;
  height: 98px;
  background: url("./time.svg");
  background-position: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  object-fit: contain;
  margin-right: 44px;
}

.text > *{
  line-height: 1.33!important;
  color: var(--color-info)!important;
}