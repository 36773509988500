@import "../../styles/core/variables.css";

.avatar {
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  padding: 3px;
  position: relative;
}

.content {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  transform: translate(-50%, -50%);
}

.noSrc {
  opacity: 0;
}

:global .lazyloading {
  opacity: 0;
}

:global .lazyloaded {
  opacity: 1;
}

.bordered {
  outline: 2px solid var(--color-white);
  border: 1px solid var(--color-white-border);
}

.bordered .content{
  width: calc(100% - 6px);
  height: calc(100% - 6px);
}
.responsive {
  width: 100%;
}

.xs {
  width: 20px;
  height: 20px;
}

.sm {
  width: 36px;
  height: 36px;
}

.md {
  width: 45px;
  height: 45px;
}

.xl {
  width: 60px;
  height: 60px;
}
