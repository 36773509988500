@import "../../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  height: var(--size-header-height);
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 calc(calc(100% - var(--grid-100)) / 2);
  height: var(--size-header-height);
  background-color: var(--color-white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.06);
}

.section {
  display: flex;
  height: 100%;
  align-items: center;
}

.menuIcon {
  display: block;
  width: 54px;
  height: 54px;
  padding: 0;
  margin-right: 10px;
  text-align: center;
}

.menuIcon svg {
  width: 24px;
  height: 24px;
}

.logo {
  display: block;
  width: var(--logo-width);
  height: var(--logo-height);
  background-position: 50% 0;
  background-size: contain;
}

.menu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 12px;
}

.menu a {
  font-family: var(--font-main);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: normal;
  color: var(--color-default);
  word-wrap: normal;
  margin-left: 0;
  margin-right: 26px;
  width: auto;
}

.menu a:hover {
  color: var(--color-primary-button);
}

.register {
  height: 60px;
}

.user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-left: 1px solid rgba(75, 75, 77, 0.24)
}

.toggle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.language {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin: 14px;
  margin-right: 0;
  padding-right: 14px;
}

.nonAuthLanguage {
  padding-right: 0;
  margin: 14px 0 13px 14px;
}

.lightLanguage {

}

.username {
  padding-left: 15px;
  padding-right: 15px;
}

.username > * {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 80px;
  height: 1.2em;
  white-space: nowrap;
}

.languageName {
  padding-right: 7px;
}

.lightLanguageName > * {
  color: #FFFFFF;
}

.user .search {
  margin: 14px;
  border-radius: 50%;
}

.search:after {
  border: 0 !important;
}

.icon {
  position: relative;
  left: -3px;
  width: 20px;
  height: 20px;
  transition: transform .1s;
}

.iconUp {
  transform: rotate(180deg)
}

.iconLight {
  top: 0px !important
}

.iconLight path {
  fill: #FFFFFF !important;
}

.links {
  display: flex;
  flex-direction: column;
  padding: 8px 40px 8px 16px;
}

.link {
  color: var(--color-default);
  line-height: 2.13;
  cursor: pointer;
}

.link:hover, .linkActive {
  color: var(--color-primary);
}


.loaderWrapper {
  width: 50px;
  height: 50px;
  margin-left: 27px;
  position: relative;
  cursor: pointer;
}

.loader {
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader div:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  height: 30%;
  transform: translate(-50%, -50%);
  background-color: rgba(193, 156, 112, 0.78);
  opacity: 1;
  border-radius: 50%;
}

.loader div:nth-child(2), .loader div:nth-child(3) {
  position: absolute;
  border: 4px solid rgba(193, 156, 112, 0.78);
  opacity: 1;
  border-radius: 50%;
  animation: loader 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader div:nth-child(3) {
  animation-delay: -1s;
}

.menuMobile {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(51, 66, 91, 0.9);
  z-index: 99999;
}

.menuMobileOpen {
  display: block;
}

.menuMobileInner {
}

.menuClose {
  position: static;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIxNyIgdmlld0JveD0iMCAwIDE3IDE3Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgZmlsbD0iI0ZGRiI+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM0NCAtMjcpIHRyYW5zbGF0ZSgzNDQgMjcpIG1hdHJpeCgtMSAwIDAgMSAxNyAwKSI+CiAgICAgICAgICAgICAgICAgICAgPHJlY3Qgd2lkdGg9IjIxIiBoZWlnaHQ9IjMiIHg9Ii0yIiB5PSI3IiByeD0iMS41IiB0cmFuc2Zvcm09InNjYWxlKC0xIDEpIHJvdGF0ZSg0NSAwIC0xMi4wMikiLz4KICAgICAgICAgICAgICAgICAgICA8cmVjdCB3aWR0aD0iMjEiIGhlaWdodD0iMyIgeD0iLTIiIHk9IjciIHJ4PSIxLjUiIHRyYW5zZm9ybT0icm90YXRlKDQ1IDguNSA4LjUpIi8+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
  background-repeat: no-repeat;
  background-position: 50%;
  width: 40px;
  height: 40px;
}

.menuControl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 12px;
  height: 78px;
}

.menuControlLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menuSearch {
  width: 40px;
  height: 40px;
}

.menuSearch svg {
  width: 17px;
  height: 18px;
}

.menuSearch svg path {
  fill: #fff !important;
}

.menuLanguage {

}

.menuModal {
}


.menuModal > section {

}

.menuModal > section > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 78px);
}

.menuModal > section > div > a {
  line-height: 2.86;
  height: auto!important;
  padding: 0 0!important;
}

.menuModal > section > div > a > span {
  text-align: center;
  width: 100%;
  font-family: var(--font-header);
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: normal;
  color: #FFFFFF!important;
}


.menuModal > section > div > a > span:hover {
  color: #e8b66c!important;
}


@keyframes loader {
  0% {
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.burger {
  display: none;
  width: 21px;
  height: 15px;
  object-fit: contain;
  background-image: url("./burger.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto;
  cursor: pointer;
  padding: 26px;
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

@media (max-width: 1280px) {
  .root, .wrapper {
    height: 78px;
  }

  .logo {
    width: 189px;
    height: 51px;
  }

  .loaderWrapper {
    display: none;
  }

  .menu {
    display: none;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .avatar {
    width: 48px;
    height: 48px;
    border: solid 1px #efefef;
  }

  .icon {
    left: 0;
    top: 2px;
  }

  .username {
    display: none;
  }

  .burger {
    display: block;
  }

  .burgerHide {
    visibility: hidden;
  }
}