:root {

  --color-black: #000;
  --color-white: #fff;
  --color-white-background: #e9e9e9;
  --color-white-border: #efefef;
  --color-dark-white: rgba(255, 255, 255, 0.78);
  --color-simply-white: #e1e1e1;
  --color-gray: #33425b;
  --color-dark: #1f242c;
  --color-dark-blue: #33425b;
  --color-light-gray: #f3f3f1;
  --color-border-button: #c39b70;
  --color-input-default: #d6d6d6;
  --color-input-hover: #c39b70;
  --color-input-error: #e16161;
  --color-gray-light: #949494;
  --color-footer-slider: #e6e6e1;

  --color-default: #0c0c0c;
  --color-muted: #989898;
  --color-info: #33425b;
  --color-primary-button: #c39b70;
  --color-primary-button-active: #886949;
  --color-primary: #886949;
  --color-primary-hover: var(--color-default);
  --color-success: #01a441;
  --color-danger: #e63a40;

  --color-orange: #e8b66c;
  --color-light-brown: #c39b70;
  --color-item-border: #e9e9e9;

  --font-size-100: 12px;
  --font-size-150: 13px;
  --font-size-200: 14px;
  --font-size-250: 14px;
  --font-size-300: 16px;
  --font-size-350: 16px;
  --font-size-400: 18px;
  --font-size-500: 20px;
  --font-size-600: 30px;
  --font-size-700: 40px;
  --font-size-800: 60px;
  --font-size-900: 80px;

  --line-height-100: 9px;
  --line-height-200: 12px;
  --line-height-300: 13px;
  --line-height-400: 20px;
  --line-height-500: 22px;
  --line-height-600: 25px;
  --line-height-700: 30px;
  --line-height-800: 40px;
  --line-height-900: 91px;

  --indent-xxxxxs: 2px;
  --indent-xxxxs: 4px;
  --indent-xxxs: 6px;
  --indent-xxs: 8px;
  --indent-xs: 10px;
  --indent-sm: 12px;
  --indent-md: 16px;
  --indent-lg: 20px;
  --indent-xl: 24px;
  --indent-xxl: 32px;
  --indent-xxxl: 40px;
  --indent-xxxxl: 48px;

  --font-weight-normal: 91px;

  --font-main: 'Muller', Roboto, Helvetica neue, Helvetica, sans-serif;
  --font-header: 'YesevaOne', Roboto, Helvetica neue, Helvetica, sans-serif;

  --font-weight-bold: 600;

  --size-header-height: 113px;
  --size-footer-height: 234px;
  --size-advantages-height: 470px;

  --grid-100: 1280px;


  --logo-width: 268px;
  --logo-height: 79px;

  --input-width: 100%;
  --input-height: 60px;

  --item-width: 290px;
  --item-height: 329px;

  --advantage-width: 282px;
  --advantage-height: 470px;

  --advantage-icon-width: 139px;
  --advantage-icon-height: 139px;

  --history-width: 100%;
  --history-height: 700px;

  --history-rectangle-width: 70%;
  --history-rectangle-width-center: 100%;
  --history-rectangle-height: 532px;

  --history-background-width: 646px;
  --history-background-height: 464px;

  --history-icon-width: 139px;
  --history-icon-height: 139px;

  --input-width-sm: 231px;
  --input-height-sm: 48px;
}
