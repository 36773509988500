@import "../../../../assets/styles/core/variables.css";

.root {
  width: 424px;
  height: 350px;
  box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.19);
  background-color: var(--color-white);
}

.inner {
  width: 100%;
  height: 100%;
  padding: 13px 13px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.inputWrapper {

}

.list {
  max-height: 100%;
}

.input {
  min-height: 40px;
  padding-bottom: 21px;
}


@media (max-width: 1280px) {
  .root {
    max-width: 100vw;
  }
}