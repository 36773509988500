@import "../../../assets/styles/core/variables.css";

.wrapper {
  width: 100%;
  margin: 12px auto;
  padding: 24px;
}

.chapter {
  padding: 24px 0 8px;
}
.rules {
  white-space: pre-wrap;
}

.wrapperButton {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.preview {
  width: 160px;
}
.send {
  width: 160px;
}