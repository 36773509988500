@import "../../../styles/core/variables.css";

.button {
  appearance: none;
  -webkit-appearance: none;
  text-align: center;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  align-items: center;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  position: relative;
  user-select: none;
  line-height: 16px;
  font-family: inherit;
  text-decoration: none;
  transition: background-color .2s, color .2s;
}

.button[disabled] {
  opacity: .3 !important;
  cursor: not-allowed;
  pointer-events: none;
  background-color: transparent;
}

.button:focus,
.button:active {
  outline: none;
  background: none;
}

.button:hover {
  background: none;
}

.block {
  display: flex;
  width: 100%;
}

.allRadius {
  border-radius: 100px;
}

.content {
  position: relative;
  z-index: 2;
}

.sm {
  font-size: var(--font-size-300);
  padding: 6px 8px;
}

.md {
  font-size: var(--font-size-400);
  padding: var(--indent-md) var(--indent-lg);
}

.lg {
  font-size: var(--font-size-500);
  padding: calc(var(--indent-sm) + 2px) var(--indent-xl);
}

.sm.square {
  padding: 6px;
}

.md.square {
  padding: var(--indent-sm);
}

.lg.square {
  padding: calc(var(--indent-sm) + 2px);
}

.link {
  padding: 0;
  /*font-size: inherit;*/
  /* не применяется размер если стоит это свойство */
  height: auto;
  text-decoration: none;
  white-space: normal;
  text-align: left;
}

.link[disabled] {
  background: none;
}

.solid {
  border-radius: 0;
}

.submit {
  border-radius: 0;
}

.solid.allRadius {
  border-radius: 100px;
}

.submit.allRadius {
  border-radius: 100px;
}

.outline:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 0;
  border: 1px solid var(--color-border-button);
}

.primary {
  color: var(--color-primary);
}

.primary.dark,
.primary.dark:hover,
.primary.dark.active {
  color: var(--color-white);
}

.primary.solid {
  color: var(--color-white);
  background: var(--color-primary-button);
}

.primary.solid:hover,
.primary.solid.active {
  color: var(--color-white);
  background: var(--color-primary-button-active);
}

.primary.submit {
  color: var(--color-white);
  background: var(--color-primary-button);
}

.primary.submit:hover,
.primary.submit.active {
  color: var(--color-white);
  background: var(--color-primary-button-active);
}

.primary.solid.dark {
  color: var(--color-primary-button);
  background: var(--color-white);
}

.primary.solid.dark:hover,
.primary.solid.dark.active {
  color: var(--color-primary-button-active);
  background: var(--color-white);
}

.primary.submit.dark {
  color: var(--color-primary-button);
  background: var(--color-white);
}

.primary.submit.dark:hover,
.primary.submit.dark.active {
  color: var(--color-primary-button-active);
  background: var(--color-white);
}

.primary.outline:active,
.primary.outline:hover,
.primary.outline.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.primary.outline:active svg path,
.primary.outline:hover svg path,
.primary.outline.active svg path {
  fill: var(--color-white);
}


.primary.link:active,
.primary.link:hover,
.primary.link.active {
  color: var(--color-primary);
}

.stroke:hover,
.stroke:active,
.stroke.active {
  background: none;
}

.primary.stroke.dark:hover,
.primary.stroke.dark:active,
.primary.stroke.dark.active {
  background: var(--color-primary);
}

.youtube,
.youtube.dark {
  color: var(--color-youtube);
}

.youtube.solid.dark,
.youtube.solid.dark:hover,
.youtube.solid,
.youtube.solid:hover {
  color: var(--color-white);
  background: var(--color-youtube);
}

.youtube.submit.dark,
.youtube.submit.dark:hover,
.youtube.submit,
.youtube.submit:hover {
  color: var(--color-white);
  background: var(--color-youtube);
}

.success.solid {
  color: var(--color-white);
  background: var(--color-success);
}

.success.submit {
  color: var(--color-white);
  background: var(--color-success);
}

.muted {
  color: var(--color-muted) !important;
}

.muted.link:hover,
.muted.link.active {
  color: var(--color-grey-400) !important;
}

.muted.link.dark {
  color: var(--color-white) !important;
}

.muted.link.dark:hover .content {
  text-decoration: underline;
}

.default {
  color: var(--color-grey-400);
}

.default.link:hover,
.default.link.active {
  color: var(--color-grey-600);
}

.muted.link.dark,
.default.link.dark {
  color: var(--color-white);
}

.muted.link.dark:hover .content,
.default.link.dark:hover .content {
  text-decoration: underline;
}

.info.solid {
  color: var(--color-primary);
  background: var(--color-grey-200);
}

.info.submit {
  color: var(--color-primary);
  background: var(--color-grey-200);
}

.warning.link {
  color: var(--color-warning);
}


@media (max-width: 1280px) {
  .sm {
    font-size: var(--font-size-250);
  }

  .md {
    font-size: var(--font-size-300);
  }

  .lg {
    font-size: var(--font-size-300);
  }
}