@import "../../../styles/core/variables.css";


.item {
  text-align: left;
  min-height: 26px;
  white-space: nowrap; /* иначе схлапывается в две строки менюшка внутри дропдауна */
}

.wrap {
  white-space: normal;
}

.sm {
  min-height: 20px;
  font-size: var(--font-size-300);
  padding-top: 2px;
  padding-bottom: 2px;
}

/* Повышаем специфичность селектора, т.к глобальные стили mu перекрывают все */
.item.primary {
  height: 32px;
  padding-left: var(--indent-sm);
  padding-right: var(--indent-xl);
  color: var(--color-primary) !important;
}

.item.primary:hover,
.item.primaryActive {
  color: var(--color-primary-hover) !important;
}

.secondaryActive {

}

.thirdActive {
  color: var(--color-gray);
}
