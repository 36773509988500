@import "../../../../assets/styles/core/variables.css";

.root {
  width: 100%;
  height: 697px;
  position: relative;
}

.wrapper {
  width: 510px;
  height: 471px;
  position: absolute;
  top: 50%;
  padding: 49px;
  left: calc(50% - 640px);
  transform: translateY(-50%);
  background-color: var(--color-info);
}

.section,
.min,
.border {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  height: 150px;
}

.section {
  padding-top: 20px;
}

.min {
  height: 80px;
}

.border {
  border-top: solid 1px rgba(151, 151, 151, 0.15);
  border-bottom: solid 1px rgba(151, 151, 151, 0.15);
}

.button {
  color: var(--color-white);
}

.title {
  font-size: 40px;
  color: #ffffff;
}

.city {
  font-family: var(--font-main);
  color: #e8b66c;
}

.address {
  font-family: var(--font-main);
  color: #9eafca;
}
.mapWrapper {
  width: 100%;
  height: 100%;
}

.mapWrapper [class*='copyrights-pane'] {
  display: none;
}

@media (max-width: 1280px) {
  .root {
    height: auto;
  }

  .wrapper {
    top: auto;
    width: 100%;
    height: auto;
    position: static;
    bottom: 0;
    left: 0;
    padding: 5px 23px 23px 23px;
    transform: none;
  }

  .title {
    font-size: var(--font-size-600);
  }

  .city {
    font-size: var(--font-size-400);
    font-weight: 500;
    line-height: 1.333;
    letter-spacing: 1px;
  }

  .address {
    font-size: var(--font-size-300);
    line-height: 1.56;
  }

  .button {
    font-size: var(--font-size-300);
  }

  .mapWrapper {
    width: 100%;
    height: 50vh;
  }
}