@import "../../../../assets/styles/core/variables.css";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 calc(calc(100% - var(--grid-100)) / 2);
  height: auto;
  background-color: transparent;
  position: relative;
}

.header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 40px 0;
  margin-bottom: 40px;
  border-bottom: solid 1px rgba(152, 209, 189, 0.33);
}

.form {
  width: 503px;
}

.headerWrapper {
  padding-left: 40px;
}

.headerText > * {
  line-height: 1.86 !important;
}

.headerTitle {
  padding-bottom: 0;
  display: flex;
  align-items: center;
}

.tooltip {
  display: inline-block;
  margin-left: 14px;
  cursor: pointer;
}

.buttonSkin {
  width: 236px;
  height: 52px;
  margin-top: 8px;
}


@media (max-width: 1280px) {
  .wrapper {
    padding: 0 10px;
  }

  .form {
    width: 100%;
  }

  .header {
    align-items: flex-start;
  }

  .headerWrapper {
    padding: 0 10px;
  }

  .buttonSkin {
    display: none;
  }
}