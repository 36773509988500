.logo {
  width: var(--logo-width);
  height: var(--logo-height);
  background: url('./logo.png') no-repeat center center;
  background-size: 100% auto;
  display: inline-block;
}

.logoMini {
  width: 40px;
  height: 40px;
  background: url('./logoMini.svg') no-repeat center center;
  background-size: 100% auto;
  display: inline-block;
}

@media (max-width: 350px) {
  .logo {
    width: 110px;
  }
}
