@import "./variables.css";

html, body {
  touch-action: manipulation;
  background: var(--color-white);
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body * {
  box-sizing: border-box;
}

body {
  margin: 0;
  color: var(--color-black);
  font-family: var(--font-main);
  -webkit-text-size-adjust: 100%;
}

a {
  text-decoration: none;
}

form {
  margin: 0;
}

@page {
  margin: 0;
  position: relative;
}

@media print {
  body, html {
    background: var(--color-white) !important;
  }
}
