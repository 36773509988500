@import "../../../assets/styles/core/variables.css";

.wrapper {
  width: 257px;
  height: 308px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.wrapper .image {
  width: 203px;
  height: 251px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 27px;
}

.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 26px;
  height: 26px;
}

.wrapper svg.icon path {
  fill: var(--color-primary);
}

.send {
  width: 100%;
  font-size: 16px !important;
  pointer-events: none;
}

@media (max-width: 1280px) {

}