.iconPack path {
  fill: inherit;
}

.icon {
  vertical-align: middle;
  display: inline-block;
  background: no-repeat center;
}

.textIcon {
  width: auto !important;
}
