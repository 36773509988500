@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: var(--grid-100);
  margin: auto;
  border: solid 1px #e9e9e9;
  margin-top: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
}

.leftBlock {
  width: 820px;
  min-height: 528px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 4px;
  position: relative;
}

.backgroundBlock {
  margin: 19px auto;
  width: 340px;
  height: 300px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}

.imagesBlock {
  width: 100%;
  height: 83px;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.imagesBlockWrapper {
  width: calc(100% + 20px);
  padding-bottom: 20px;
  height: 103px;
  overflow-x: auto;
}

.imagesBlockInner {
  width: max-content;
  height: 103px;
}

.imageBlock {
  width: 73px;
  height: 73px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  margin: 4px;
  display: inline-block;
  cursor: pointer;
}

.imageBlock.active {
  border: 1px solid var(--color-input-hover);
  box-shadow: 0 0 4px 0 #c39b70;
}

.rightBlock {
  width: 405px;
  min-height: 536px;
  margin-right: 59px;
  margin-left: 59px;
}

.nameBlock {
  width: 100%;
  padding-top: 56px;
  padding-bottom: 11px;
}

.name {
  color: var(--color-info) !important;
  font-family: var(--font-header);
  line-height: 1.33;
}

.lot {
  font-weight: 500;
  line-height: 1.57;
  color: #9b9b9b;
  padding-top: 24px;
}

.priceWrapper {
  padding: 24px 0;
}

.priceWrapper .form {
  min-width: 369px;
  align-items: flex-start;
}

.priceWrapper .form button {
  width: 100%;
}

.separate {
  width: 100%;
  border-bottom: solid 1px #d6daed;
}

.descriptionWrapper {
  opacity: 0.78;
  padding: 31px 0 80px;
}

.description {
  font-family: var(--font-main);
}

.startPrice {
  font-family: Muller;
  line-height: 1.22;
  color: var(--color-info);
}

.startTime {
  font-family: Muller;
  line-height: 1.22;
  color: #b0895f;
}

.finishWrapper {
  width: 232px;
  height: 68px;
  background-image: url("./orangeLine.svg");
  background-position: -178px 0;
  padding: 4px 0 0 16px;
  margin-bottom: 16px;
}

.finishCaptionText {
  font-family: YesevaOne;
  line-height: 1.33;
  color: #fff;
}

.finishDescription {
  color: #fff;
}

.heart {
  width: 100px;
  height: 100px;
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  transform: translate(25%, -25%) scale(.75);
  background: url(./heart.png) no-repeat;
  background-position: 0 0;
  transition: background 0s steps(0);
  cursor: pointer;
}

.heart.active {
  background-position: -2800px 0;
  transition: background 1s steps(28);
  animation: fave-heart 1s steps(28);
}

@keyframes fave-heart {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -2800px 0;
  }
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.form > * {
  max-width: 180px;
}

.formHeader {
  font-family: var(--font-main);
  padding-bottom: 10px;
}

.select {
  width: 180px;
}

.select > span {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}

.select > span > span {
  overflow: hidden;
  line-height: 18px;
}

.select > ul {
  max-height: 300px;
  overflow: auto;
}

.formButton {
  height: 60px;
}

.line {
  width: 100%;
  height: 48px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 30px 0 30px;
  padding: 13px 30px;
}

.lineText {
  color: #fff;
}

.orangeColorLine {
  background-image: url("./orangeLine.svg");
}
.redColorLine {
  background-image: url("./redLine.svg");
}
.blueColorLine {
  background-image: url("./blueLine.svg");
}
.greenColorLine {
  background-image: url("./greenLine.svg");
}


@media (max-width: 1280px) {
  .wrapper {
    flex-direction: column;
    width: calc(100% - 12px);
    background-color: #FFFFFF!important;
    margin: 6px;
  }
  .leftBlock {
    width: 100%;
    height: auto;
    min-height: auto;
    margin-top: 36px;
  }
  .imagesBlock {
    display: none;
  }
  .backgroundBlock {
    max-width: 340px;
    max-height: 300px;
  }
  .rightBlock {
    max-width: 405px;
    width: 100%;
    padding: 0 10px;
  }

  .priceWrapper .form {
    flex-direction: column;
    max-width: 100%;
    min-width: 100%;
  }
  .priceWrapper .form > * {
    width: 100%!important;
    max-width: 100%;
    margin-bottom: 12px;
  }

  .nameBlock {
    padding-top: 16px;
  }

  .name {
    font-size: 26px;
    line-height: 1.23;
    color: var(--color-info);
  }

  .lot {
    font-size: 14px;
    padding-top: 9px;
  }
}