@import "../../../../assets/styles/core/variables.css";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 0 calc(calc(100% - var(--grid-100)) / 2);
  height: var(--size-footer-height);
  background-color: var(--color-dark);
  position: relative;
}

.inner {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-top: 43px;
}

.section {
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
}

.bottom {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.11);
  margin-top: 29px;
  font-size: var(--font-size-150);
  line-height: 1;
  padding-top: 24px;
  color: #949494;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 50px;
}

.top {
  justify-content: center;
}

.wrapper .section .subscribeTitle {
  color: var(--color-orange);
  font-size: var(--font-size-250);
  font-weight: bold;
  padding-bottom: 12px;
}

.button {
  color: var(--color-white);
}


@media (max-width: 1280px) {
  .wrapper {
    height: auto;
    padding: 10px;
  }
  .inner {
    flex-direction: column;
    padding: 12px 4px;
    align-items: flex-start;
  }
  .inner > *, .bottom > * {
    padding-bottom: 24px;
  }
  .bottom > * {
    padding: 12px 4px;
  }
  .bottom {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0;
    margin-top: 0;
  }
}