@import "../../../assets/styles/core/variables.css";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 632px;
}

.wrapper.positionLeft {
  align-items: flex-start;
}

.wrapper.positionCenter {
  align-items: center;
}

.wrapperInputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.wrapper>* {
  margin-bottom: 40px;
}

.send {
  width: 369px;
  height: 60px;
}

.line {
  width: 100%;
  height: 1px;
  opacity: 0.33;
  background-color: #979797;
}

.text {
  font-size: 24px !important;
}

.inner {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

@media (max-width: 1280px) {
  .wrapper {
    min-width: 0px;
  }

  .wrapper > * {
    margin-bottom: 16px;
  }

  .wrapperInputs {
    flex-direction: column;
    align-items: flex-start;
  }
  .wrapperInputs > * {
    margin-bottom: 16px;
  }
  .send {
    width: 100%;
    max-width: 369px;
  }
}