@import "../../../styles/core/variables.css";

.section + .section {
  padding-top: var(--indent-md);
}


/* TODO: унифицировать */
.title {
  margin-bottom: var(--indent-xs);
}

.section.primary {
  padding: var(--indent-xs) 0;
}

/* TODO: унифицировать */
.section.primary + .section {
  border-top: 1px solid var(--color-gray);
}

.section.primary.dark {
  border: none;
}

.item {
  justify-content: flex-start;
  margin: 0 22px;
  padding: 0 22px;
  width: calc(100% - 44px);
  height: 78px !important;
  white-space: nowrap;
  border-bottom: solid 1px rgba(151, 151, 151, 0.78);
}

.item:last-child {
  border-bottom: 0;
}

.wrap {
  white-space: normal;
}

.sm {
  min-height: 20px;
  font-size: var(--font-size-300);
  padding-top: 2px;
  padding-bottom: 2px;
}

.item.primary {
  font-size: var(--font-size-400);
  font-family: var(--font-header) !important;
  font-weight: normal;
  padding-left: var(--indent-sm);
  padding-right: var(--indent-xl);
  color: var(--color-white) !important;
}

.item.primary:hover,
.item.primaryActive {
  color: var(--color-primary) !important;
}

.secondaryActive {

}

.thirdActive {
  color: var(--color-primary);
}
