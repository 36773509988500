@import "../../../../assets/styles/core/variables.css";

.root {
  width: 947px;
  padding-top: 40px;
  padding-bottom: 62px;
  margin-right: 40px;
}

.menu {
  width: 290px;
  height: 468px;
  border-radius: 3px;
  background-color: var(--color-info);
}

.inner {
  width: 100%;
  padding: 20px;
  background-color: var(--color-white);
}

.controlInner {
  padding: 21px;
  background-color: var(--color-simply-white);
  margin-bottom: 23px;
}

.title {
  margin-bottom: 9px;
}
.textInfo {
  font-weight: normal!important;
}
.options {
}
.search {
  width: 290px!important;
  height: 60px!important;
  margin-right: 18px;
  top: -2px;
}

.searchInput {
  width: 290px!important;
  height: 58px!important;
  margin-right: 18px;
}

.find {
  width: 166px;
  height: 60px;
}

@media (max-width: 1280px) {
  .root {
    padding: 0 10px;
    width: 100%;
  }

  .search {
    width: calc(100% - 184px)!important;
  }
  .searchInput {
    width: 100%!important;
  }
}