@import "../../../../assets/styles/core/variables.css";

.wrapper {
  width: 100%;
  height: max-content;
  min-height: 335px;
  background-image: linear-gradient(to bottom, rgba(228, 228, 228, 0.48), rgba(255, 255, 255, 0.49));
  position: relative;
  margin-top: 82px;
}

.lineWrapper {
  width: 100%;
  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;
  z-index: 20;
}

.line {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
  position: relative;
}

.tribune {
  width: 1180px;
  height: 179px;
  background: url("./tribune.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: -157px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
}

.lots {
  width: 358px;
  height: 120px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.82);
  position: absolute;
  bottom: 22px;
  left: 182px;
}

.lotsLineWrapper {
  width: 329px;
  height: 7px;
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0.08);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.lotsLine {
  height: 7px;
  border-radius: 1px;
  background-image: linear-gradient(to left, #e3cba9 112%, #c39b70);
}

.lotText {
  text-align: center;
  padding-top: 22px;
  width: 100%;
}

.title {
  padding: 18px 0;
}

.titleLine {
  text-align: center;
}


@media (max-width: 1280px) {
  .wrapper {
    margin: 0;
    height: auto;
    min-height: auto;
  }

  .lineWrapper {
    display: none;
  }

  .tribune {
    background: none;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    width: 100%;
    height: auto;
    margin-top: 120px
  }

  .lots {
    left: auto;
    width: 100%;
    bottom: auto;
    position: relative;
    height: auto;
    display: flex;
    padding: 0 15px;
    align-items: flex-start;
    flex-direction: column;
    background: transparent;
  }

  .title {
    padding-top: 20px;
    padding-bottom: 0;
    width: 100%;
  }

  .titleLine {
    font-size: var(--font-size-200);
    text-align: left;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .lotsLineWrapper {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
  }

  .lotText {
    font-size: var(--font-size-200);
    text-align: left;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}